<template>
    <BasicInformationCard title="Werkrooster"
    :edit-router-link="{name: routeNames.USER_DETAIL_EDIT_WORK_SCHEDULE, params: { userId: userId}}"
    :promises.sync="promises">
        <template slot="content">
            <v-container>
                <UserWorkScheduleTable :work-schedule="getUserWorkSchedule"/>
            </v-container>
        </template>
    </BasicInformationCard>
</template>
<script>
import BasicInformationCard from '../shared/BasicInformationCard.vue';
import routeNames from "@/router/RouteNames";
import UserWorkScheduleTable from './UserWorkScheduleTable.vue';


export default {
    name: "UserWorkScheduleComponent",
    props: {
        userId: {
            type: String,
            required: true
        }
    },
    components: {
        BasicInformationCard,
        UserWorkScheduleTable
    },
    data() {
        return {
            promises: [
                this.$store.dispatch('usersModule/fetchUserWorkSchedule', this.userId).then((result) => {
                    this.workschedule = result
                })
            ],
            workschedule: null,
            routeNames
        }
    },
    computed: { 
        getUserWorkSchedule() {
            return this.$store.state.usersModule.userWorkSchedule
        }
    },
}
</script>