<template>
    <BasicInformationCard title="Persoonlijke informatie" 
        :editRouterLink="{name: routeNames.USER_DETAIL_EDIT_PERSONAL_INFORMATION, params: { userId: userId}}"
        :promises.sync="promises">
        <template slot="content">
            <v-container>
                <v-row >
                    <v-col cols="12" sm="6" md="4">
                        <BasicInformationItem description="Geboortedatum" :value="applyDateFormat(getUserPersonalInformation.birthDate)"/>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <BasicInformationItem description="Nationaliteit" :value="getUserPersonalInformation.nationalities">
                            <template v-slot:value-display="slotProps">
                                <ul class="comma-separated subtitle-1 font-weight-medium black--text">
                                    <li v-for="nationality in slotProps.value" v-bind:key="nationality">
                                        <country-flag :country="nationality" size="small" rounded/>
                                        {{ translateNationalitiy(nationality) }}</li>
                                </ul>
                            </template>
                        </BasicInformationItem>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <BasicInformationItem description="Geboorteplaats" :value="getUserPersonalInformation.birthPlace"/>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <BasicInformationItem description="Geslacht" :value="translateGender(getUserPersonalInformation.gender)"/>

                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <BasicInformationItem description="Burgerlijke staat" :value="translateCivilStatus(getUserPersonalInformation.civilStatus)"/>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <BasicInformationItem description="Rijksregisternummer" :value="getUserPersonalInformation.taxIdentificationNumber"/>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <BasicInformationItem description="Bankrekening" :value="getUserPersonalInformation.bankAccountNumber"/>
                    </v-col>
                    <v-col cols="12" sm="6" md="4">
                        <BasicInformationItem description="Personen ten laste" :value="translateDependents(getUserPersonalInformation.dependents)"/>
                    </v-col>
                    <v-col cols="12" sm="6" md="4"></v-col>
                </v-row>
            </v-container>
        </template>
    </BasicInformationCard>
</template>

<script>
import BasicInformationCard from '../shared/BasicInformationCard.vue';
import BasicInformationItem from '../shared/BasicInformationItem.vue';
import routeNames from "@/router/RouteNames";
import {translateNationalitiy, translateGender, translateCivilStatus, translateDependents} from "@/shared/utils/translateUtils";
import CountryFlag from 'vue-country-flag'
import {applyDateFormat} from "@/shared/utils/dateUtils";

export default {
    name: "UserPersonalInformationComponent",
    props: {
        userId: {
            type: String,
            required: true
        }
    },
    components: {
        BasicInformationCard,
        BasicInformationItem,
        CountryFlag
    },
    methods: {
        translateNationalitiy,
        translateGender,
        translateCivilStatus,
        translateDependents,
        applyDateFormat
    },
    data() {
        return {
            routeNames,
            promises: [
                this.$store.dispatch('usersModule/fetchUserPersonalInformation', this.userId).then((result) => {
                    this.userPersonalInformation = result
                })
            ],
            userPersonalInformation: null,
        }
    },
    computed: {
        getUserPersonalInformation() {
            return this.$store.state.usersModule.userPersonalInformation
        }
    }

}
</script>