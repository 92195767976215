<template>
  <div>
    <v-row>
      <v-col>
        <UserDetailHeaderComponent :user-id="userId" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="7" class="d-flex">
        <UserPersonalInformationComponent :user-id="userId" class="flex-grow-1"/>
      </v-col>
      <v-col cols="12" lg="5" class="d-flex">
        <UserContactInformationComponent :user-id="userId" class="flex-grow-1"/>
      </v-col>
      <v-col cols="12" lg="6" class="d-flex">
        <UserWorkScheduleComponent :user-id="userId" class="flex-grow-1"/>
      </v-col>
      <v-col cols="12" lg="6" class="d-flex">
        <!-- Space to add future user detail card -->
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserDetailHeaderComponent from "@/components/userDetail/UserDetailHeaderComponent";
import UserPersonalInformationComponent from "@/components/userDetail/UserPersonalInformationComponent.vue";
import UserContactInformationComponent from "@/components/userDetail/UserContactInformationComponent.vue";
import UserWorkScheduleComponent from "@/components/userDetail/UserWorkScheduleComponent.vue";

export default {
  name: "UserDetail",
  props: {
    userId: {
      type: String,
      required: true
    }
  },
  components: {
    UserDetailHeaderComponent,
    UserPersonalInformationComponent,
    UserContactInformationComponent,
    UserWorkScheduleComponent
  },
}

</script>
